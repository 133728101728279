import React from 'react'

const ProblemSolving = () => {
  return (
    <div>
      <h1>Problem Solving</h1>
      <br></br>
      <p>From the beginning of my career, I have had to learn to work with legacy systems without any formal training. I always needed to understand the problems within the system to figure out how to resolve them in the best way possible without compromising other parts of the system.</p>
    </div>
  )
}

export default ProblemSolving
