import React from 'react'

const TeamWork = () => {
  return (
    <div>
      <h1>
        TeamWork
      </h1>
      <br></br>
      <p>
      I have always gotten along well with all my colleagues and formed friendships at work. I make an effort to understand how each person works and assist them in ways they comprehend. I help my colleagues improve their skills by sharing my knowledge and experience.
      </p>
    </div>
  )
}

export default TeamWork
