import React from 'react'

const Creativity = () => {
  return (
    <div>
      <h1>
      Creativity
      </h1>
      <br></br>
      <p>
      Creativity fuels my problem-solving approach. I constantly seek innovative solutions and think outside the box to address challenges. Whether developing new processes or optimizing existing ones, my creative thinking enhances efficiency and drives progress within the team.
      </p>
    </div>
  )
}

export default Creativity
