import React from 'react'

const LeaderShip = () => {
  return (
    <div>
      <h1>LeaderShip</h1>
      <br></br>
      <p>During my time managing a carpentry business, I successfully developed and automated several processes, significantly enhancing the workflow. I implemented streamlined procedures and created comprehensive Excel spreadsheets. Instead of creating new sheets monthly or weekly, I designed an annual spreadsheet that aggregated data from all months, facilitating metrics creation and improving interdepartmental communication. This initiative not only organized the company's workflow but also fostered better collaboration between departments.</p>
    </div>
  )
}

export default LeaderShip
