import React, { useState } from 'react'
import ProblemSolving from '../charts-texts/problemSolving';
import Proactivity from '../charts-texts/proativity';
import TeamWork from '../charts-texts/teamWork';
import InterReal from '../charts-texts/interRela';
import Resilience from '../charts-texts/resilience';
import Flexibility from '../charts-texts/flexibility';
import Creativity from '../charts-texts/creativity';
import Ethic from '../charts-texts/ethic';
import Empathy from '../charts-texts/empathy';
import LeaderShip from '../charts-texts/leadership';


const SoftSkillOptions = () => {
  const [text,setText] = useState('lead')


  return (
    <>
        <div className='txt-btn-resp'>
          {text === 'proa' && <Proactivity />}
          {text === 'ps' && <ProblemSolving />}
          {text === 'team' && <TeamWork />}
          {text === 'inter' && <InterReal />}
          {text === 'resi' && <Resilience />}
          {text === 'fle' && <Flexibility />}
          {text === 'crea' && <Creativity />}
          {text === 'ethic' && <Ethic />}
          {text === 'empa' && <Empathy />}
          {text === 'lead' && <LeaderShip />}
        </div>
        <div className='skills-btn'>
            <ul>
              <li><a onClick={() => {setText('proa')}}>Proactivity</a></li>
              <li><a onClick={() => {setText('ps')}}>Problem solving</a></li>
              <li><a onClick={() => {setText('team')}}>Team Work</a></li>
              <li><a onClick={() => {setText('inter')}}>Interpersonal relationship</a></li>
              <li><a onClick={() => {setText('resi')}}>Resilience</a></li>
              <li><a onClick={() => {setText('fle')}}>Flexibility</a></li>
              <li><a onClick={() => {setText('crea')}}>Creativity</a></li>
              <li><a onClick={() => {setText('ethic')}}>Ethic</a></li>
              <li><a onClick={() => {setText('empa')}}>Empathy</a></li>
              <li><a onClick={() => {setText('lead')}}>Leadership</a></li>
            </ul>
        </div>
      </>
  )
}

export default SoftSkillOptions
