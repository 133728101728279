import React, { useEffect, useState } from 'react';
import axios from 'axios';
import'./movie-list.css'

const MovieList = ({title}) => {
  const [movies, setMovies] = useState([]);
  const [loading, setLoading] = useState(true); // Estado de carregamento
  const [error, setError] = useState(null); // Estado de erro
  const [selectMovie,setSelectMovie] = useState(null)

  useEffect(() => {
    // Função para buscar dados da API
    const fetchMovies = async () => {
      try {
        if(!title) return;
        const response = await axios.get(`http://www.omdbapi.com/?apikey=7e039685&s=${title}`); // Substitua pela URL da sua API
        const data = response.data;
        if (Array.isArray(data.Search)) {
          setMovies(data.Search); // Supondo que a resposta seja um array de filmes
        } else {
          setMovies([]); // Caso a resposta não seja um array, inicialize com um array vazio
        }
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar filmes:', error);
        setError('Erro ao buscar filmes');
        setLoading(false);
      }
    };

    fetchMovies();
  }, [title]);

  if (loading) {
    return <div>Carregando...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className='movie-resposta'>
        <div className='movie-head'>
            <h1>Lista de Filmes</h1>
        </div>
        <div className='corpo-movie'>
            
                {movies.filter(movie => movie.Poster !== 'N/A').map((movie) => (
                    selectMovie === movie.imdbID ? (
                        <div key={movie.imdbID}
                        className="select-div"
                        onClick={() => setSelectMovie(movie.imdbID)}
                        >
                            <a 
                            href={`https://www.imdb.com/title/${movie.imdbID}/?ref_=fn_al_tt_1`} target='_blank'
                            className={`movie-card ${selectMovie === movie.imdbID ? 'selected': ''}`}>
                                <img className='movie-poster' src={movie.Poster} alt={`${movie.Title} Poster`} />
                                <h2 className='movie-title'>{movie.Title} ({movie.Year})</h2>
                                {/* <p className='movie-type'>Tipo: {movie.Type}</p> */}
                            </a>
                    </div>
                    ) :('')))}

                    <ul class="ul-body">
                        {movies.filter(movie => movie.Poster !== 'N/A').map((movie) => (
                        (
                            <li key={movie.imdbID}
                            className="li-card"
                            onClick={() => setSelectMovie(movie.imdbID)}
                            >
                                <a className="movie-ca" >
                                    <img className='movie-poster' src={movie.Poster} alt={`${movie.Title} Poster`} />
                                    <h2 className='movie-title'>{movie.Title} ({movie.Year})</h2>
                                    {/* <p className='movie-type'>Tipo: {movie.Type}</p> */}
                                </a>
                            </li>
                        )))}
                    </ul>
                
            
        </div>
    </div>
  );
};

export default MovieList;
