import React from 'react'

const Empathy = () => {
  return (
    <div>
      <h1>
      Empathy
      </h1>
      <br></br>
      <p>
      Empathy is crucial for effective teamwork and leadership. I strive to understand the needs and emotions of my colleagues, creating a supportive and inclusive work environment. By showing genuine care and consideration, I foster strong, collaborative relationships that enhance team cohesion and productivity.
      </p>
    </div>
  )
}

export default Empathy
