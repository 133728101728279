import React, { useState } from 'react'
import MovieList from './movie-list'
import './index.css'

const MovieProject = () => {
    const [title,setTitle] = useState('One Child Nation')

    const handleSubmit = (event) => {
        event.preventDefault();
        if(title){
            setTitle(title)
        }
    }
  return (
    <div className='full-response'>
        <div className='title-res'>
            <form onSubmit={handleSubmit}>
                <input 
                type="text" 
                 
                onChange={(e) => setTitle(e.target.value)}
                placeholder='Digite o nome do Filme'
                />
            </form>
        </div>
        <div className='body-response'>
          <MovieList title={title}/>
        </div>
    </div>
  )
}

export default MovieProject
