import React from 'react'

const Ethic = () => {
  return (
    <div>
      <h1>
      Etich
      </h1>
      <br></br>
      <p>
      A strong work ethic underpins everything I do. I am committed to maintaining high standards of integrity, responsibility, and professionalism. By consistently delivering quality work and holding myself accountable, I build trust and reliability with my colleagues and clients.
      </p>
    </div>
  )
}

export default Ethic
