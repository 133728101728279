import React from 'react'

const Proactivity = () => {
  return (
    <div>
      <h1>
      Proactivity
      </h1>
      <br></br>
      <p>
      I am a highly proactive individual who always seeks out tasks and strives to understand the work ahead. I consistently go above and beyond what is required, doing my best to perform necessary actions and improve my work. I always prioritize maintenance and continuous improvement.
      </p>
    </div>
  )
}

export default Proactivity
