import React from 'react'

const InterReal = () => {
  return (
    <div>
      <h1>
      Interpersonal Relathionship
      </h1>
      <br></br>
      <p>
      Building strong interpersonal relationships is a cornerstone of my professional approach. I prioritize clear and empathetic communication, fostering a positive and collaborative work environment. By understanding and respecting the perspectives of my colleagues, I create an atmosphere where everyone feels valued and motivated to contribute their best.
      </p>
    </div>
  )
}

export default InterReal
