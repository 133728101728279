import React, { useState } from 'react'
import './chart.css'
import { Chart as ChartJS } from "chart.js/auto"
import { Bar, Doughnut, Line } from "react-chartjs-2"
import SoftSkills from './charts-resources/soft-skill.json'


const ChartsSoft = () => {
    
  return (
                <div className='dataCard'>
                    <Bar
                        className='bar'
                        data={{
                            labels: SoftSkills.map((data) => data.label),
                            datasets:[{
                                label:"Soft Skills",
                                data: SoftSkills.map((data => data.value)),
                                backgroundColor:["rgba(0,0,0,1)"],
                                custom: SoftSkills.map((data => data.smartIndex)),
                            }]
                        }}
                        
                />
                </div>
  )
}
export default ChartsSoft
