import React from 'react'

const HobbiesLetter = () => {
  return (
    <div className='per-let-body'>
            <div className='let-ins'>
      <p><b>
        When I’m not studying and improving my skills in my professional field, I have a few unique hobbies that I enjoy in my free time, which are not directly related to work.

        <br></br>
        <br></br>
        I played the saxophone for over three years and studied for a year at the Tatuí Conservatory, where I met great instrumentalists and discovered my passion for music, witnessing the dedication of accomplished musicians to their craft.

        <br></br>
        <br></br>
        I also bought a motorcycle to restore, focusing on its electrical system. Although the project is currently on hold, I learned a great deal about mechanics and electrical systems. This experience taught me how to diagnose issues and conduct various tests, understanding that sometimes mechanical problems can be solved with simple external adjustments.
        <br></br>
        <br></br>

        During this period, I took a TIG welding course, learning effective welding techniques and some principles of mechanical engineering. I aimed to create a stainless steel exhaust for my motorcycle, a project also paused due to the high investment required.
        <br></br>
        <br></br>

        At one point, I delved into 3D modeling for games, deepening my love for art and learning a lot about the history of art from great artists, things I hadn’t learned in school. I created numerous drawings and even did a few tattoos for myself and some acquaintances, although it was never a career path I wanted to pursue long-term, despite achieving a high skill level from the beginning.
        <br></br>
        <br></br>

        I’ve also worked on several Arduino projects, including a digital drum model and a CNC with G-Code. These Arduino projects ignited my passion for microchips and low-level programming, allowing me to understand hexadecimal port communication directly with hardware. During this time, I studied electrical components extensively, enhancing my understanding of computer hardware functionality.
        </b></p>
        
    </div>
    </div>
  )
}

export default HobbiesLetter
