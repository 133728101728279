import React from 'react'

const Resilience = () => {
  return (
    <div>
      <h1>
      Resilience
      </h1>
      <br></br>
      <p>
      Resilience has been key to my career success. Facing challenges head-on and maintaining a positive attitude, even in difficult situations, has allowed me to navigate obstacles effectively. I continuously adapt, learn from setbacks, and use these experiences to drive personal and professional growth.
      </p>
    </div>
  )
}

export default Resilience
