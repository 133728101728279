import React from 'react'

const Flexibility = () => {
  return (
    <div>
      <h1>
      Flexibility
      </h1>
      <br></br>
      <p>
      Flexibility is essential in today’s dynamic work environment. I adapt quickly to changing priorities and new challenges, ensuring that I meet project goals and deadlines. My ability to shift focus and approach tasks from different angles allows me to deliver high-quality work under varying conditions.
      </p>
    </div>
  )
}

export default Flexibility
