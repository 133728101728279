import React, { useState } from 'react'
import './charts-tab.css'
import { FcBarChart,FcCommandLine,FcAbout } from "react-icons/fc";
import ChartSoft, { option } from '../charts'
import BackEnd from '../charts-texts/backEnd';
import ProblemSolving from '../charts-texts/problemSolving';
import SoftSkillOptions from '../chart-text-sub/soft-skill-op';

const CharTabChoice = () => {
    const [body,setBody] = useState('soft')
    const [bodyText,setBodyText] = useState('1')

  return (
    <>
        <div className='div-bod'>
            <div className='esq-charts'>
                <ul className='ul-charts'>
                    <li><a onClick={() => {setBody('soft')}}><FcBarChart /></a></li>
                    <li  className="hid"><a onClick={() => {setBody('code')}}><FcCommandLine /></a></li>
                    <li className="hid"><a onClick={() => {setBody('jobs')}}><FcAbout /></a></li>
                </ul>
            </div>
            <div className='dir-charts'>
                <div className='text-charts'>
                        {bodyText === '1' && <SoftSkillOptions/>}
                </div>
                <div className='real-charts'>
                    <div className='inside-render'>
                        {body === 'soft' && <ChartSoft/>}
                    </div>
                    <div className='txt-exp'>
                        <h3>SoftSkills</h3><br></br>
                        <p>Soft skills, such as communication, teamwork, adaptability, and problem-solving, are essential in today's workplace for effective collaboration and personal success. These skills enhance interpersonal interactions and contribute to a positive work environment. To illustrate my proficiency in these areas, I will present a chart showcasing my soft skills, highlighting my strengths and areas of continuous improvement. This visual representation demonstrates my commitment to personal growth and my value as a well-rounded team member.</p>
                        <br></br>
                        <p><b>Please click on the SoftSkills that you want to read about.</b></p>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default CharTabChoice
