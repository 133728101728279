import React, { useState } from 'react'
import { FaHome } from "react-icons/fa";
import { BiMoviePlay } from "react-icons/bi";
import MovieProject from './project-movie';
import './index.css'


const HomeProjects = () => {
  const[body,setBody] = useState('mov');

  return (
    <div className='body-proj'>
      <div className='esq-menu'>
          <ul className='ul-proj'>
            <li className='apis'><a href="/"><FaHome/>
            </a></li>
            <li className='apis'><a onClick={()=>{setBody('mov')}}><BiMoviePlay />
            </a></li>
          </ul>
      </div>
      <div className='dir-body'>
        <MovieProject/>
      </div>

    </div>
  )
}

export default HomeProjects
